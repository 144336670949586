const assets = [];
assets.push([require("./images/img_0.png").default ? require("./images/img_0.png").default: require("./images/img_0.png"), 0]);
assets.push([require("./images/img_1.png").default ? require("./images/img_1.png").default: require("./images/img_1.png"), 1]);
assets.push([require("./images/img_2.png").default ? require("./images/img_2.png").default: require("./images/img_2.png"), 2]);
assets.push([require("./images/img_3.png").default ? require("./images/img_3.png").default: require("./images/img_3.png"), 3]);
assets.push([require("./images/img_4.png").default ? require("./images/img_4.png").default: require("./images/img_4.png"), 4]);
assets.push([require("./images/img_5.png").default ? require("./images/img_5.png").default: require("./images/img_5.png"), 5]);
const data = {
  "v": "5.7.14",
  "fr": 30,
  "ip": 0,
  "op": 241,
  "w": 1920,
  "h": 969,
  "nm": "名词解释。",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 1686,
      "h": 969,
      "u": "",
      "p": "img_0.png",
      "e": 0
    },
    {
      "id": "image_1",
      "w": 1252,
      "h": 1252,
      "u": "",
      "p": "img_1.png",
      "e": 0
    },
    {
      "id": "image_2",
      "w": 1226,
      "h": 1226,
      "u": "",
      "p": "img_2.png",
      "e": 0
    },
    {
      "id": "image_3",
      "w": 931,
      "h": 931,
      "u": "",
      "p": "img_3.png",
      "e": 0
    },
    {
      "id": "image_4",
      "w": 1313,
      "h": 1313,
      "u": "",
      "p": "img_4.png",
      "e": 0
    },
    {
      "id": "image_5",
      "w": 931,
      "h": 931,
      "u": "",
      "p": "img_5.png",
      "e": 0
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "往下飘的花瓣 拷贝 3.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0
                ],
                "y": [
                  0.688
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 39,
              "s": [
                100
              ]
            },
            {
              "t": 120,
              "s": [
                1
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            843,
            484.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  0.788,
                  0.788,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                47,
                47,
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.667,
                  0.667,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0.156,
                  0.156,
                  0
                ]
              },
              "t": 39,
              "s": [
                194,
                194,
                100
              ]
            },
            {
              "t": 120,
              "s": [
                326,
                326,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 121,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 120,
              "s": [
                100
              ]
            },
            {
              "t": 180,
              "s": [
                30
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 120,
      "op": 181,
      "st": 60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 180,
              "s": [
                5
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 240,
              "s": [
                100
              ]
            },
            {
              "t": 300,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 180,
      "op": 241,
      "st": 180,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 2,
      "nm": "anim_stroy_world_circle_5.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 120,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 180,
              "s": [
                20
              ]
            },
            {
              "t": 240,
              "s": [
                12
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            934,
            973.72,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            613,
            932.72,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 120,
      "op": 241,
      "st": 120,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": -60,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                100
              ]
            },
            {
              "t": 60,
              "s": [
                30
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 61,
      "st": -60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                5
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 120,
              "s": [
                100
              ]
            },
            {
              "t": 180,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 60,
      "op": 121,
      "st": 60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "anim_stroy_world_circle_5.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                20
              ]
            },
            {
              "t": 120,
              "s": [
                12
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            934,
            973.72,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            613,
            932.72,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 121,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 2,
      "nm": "anim_stroy_word_circle_3.png",
      "cl": "png",
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 5,
              "s": [
                90
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 8,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 17,
              "s": [
                90
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 73,
              "s": [
                90
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 76,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 85,
              "s": [
                90
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 130,
              "s": [
                90
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 133,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 142,
              "s": [
                90
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 198,
              "s": [
                90
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 201,
              "s": [
                100
              ]
            },
            {
              "t": 210,
              "s": [
                90
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            465.5,
            465.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 241,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 2,
      "nm": "anim_stroy_word_circle_2.png",
      "cl": "png",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.667
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 27,
              "s": [
                35
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 0,
              "s": [
                744,
                1162.5,
                0
              ],
              "to": [
                36,
                -113,
                0
              ],
              "ti": [
                -36,
                113,
                0
              ]
            },
            {
              "t": 120,
              "s": [
                960,
                484.5,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            656.5,
            656.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 241,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 2,
      "nm": "anim_stroy_word_circle_2.png",
      "cl": "png",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 35,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            656.5,
            656.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 241,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 2,
      "nm": "eff_stroy_word.png",
      "cl": "png",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 121,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 151,
              "s": [
                75
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 211,
              "s": [
                75
              ]
            },
            {
              "t": 241,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            465.5,
            465.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 121,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 241,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 121,
      "op": 242,
      "st": 121,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 2,
      "nm": "eff_stroy_word.png",
      "cl": "png",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 61,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 91,
              "s": [
                75
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 151,
              "s": [
                75
              ]
            },
            {
              "t": 181,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            465.5,
            465.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 61,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 181,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 121,
      "op": 182,
      "st": 61,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 14,
      "ty": 2,
      "nm": "eff_stroy_word.png",
      "cl": "png",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 182,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 212,
              "s": [
                75
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 272,
              "s": [
                75
              ]
            },
            {
              "t": 302,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            465.5,
            465.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 182,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 302,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 182,
      "op": 242,
      "st": 182,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 15,
      "ty": 2,
      "nm": "eff_stroy_word.png",
      "cl": "png",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 30,
              "s": [
                75
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 90,
              "s": [
                75
              ]
            },
            {
              "t": 120,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            465.5,
            465.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 0,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 120,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 121,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 16,
      "ty": 2,
      "nm": "eff_stroy_word.png",
      "cl": "png",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": -60,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": -30,
              "s": [
                75
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 30,
              "s": [
                75
              ]
            },
            {
              "t": 60,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            465.5,
            465.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": -60,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 60,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 61,
      "st": -60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 17,
      "ty": 2,
      "nm": "eff_stroy_word.png",
      "cl": "png",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 61,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 91,
              "s": [
                75
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 151,
              "s": [
                75
              ]
            },
            {
              "t": 181,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            465.5,
            465.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 61,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 181,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 61,
      "op": 121,
      "st": 61,
      "bm": 0
    }
  ],
  "markers": []
}

assets.forEach((asset) => {
  const path = typeof asset[0] === 'string' ? asset[0] : asset[0].src;
  const index = asset[1];
  data.assets[index].p = path;
});
module.exports = data;