import { useState, useRef, useEffect, memo } from 'react';
import { useLottie } from '@diezhi/yesio';
import { xiyueHome } from '@/src/config';
import useChessLottie from '@/src/hooks/useChessLottie';
import whiteCircle from './animation/lottieWhiteCircle/data.json';
import redCircle from './animation/lottieRedCircle/data.json';
import styles from './style.module.scss';

const Story = ({
  active,
}) => {
  // control type
  const [type, setType] = useState(1);
  const [subType, setSubType] = useState(1);

  // control lottie
  const lottieRef = useRef();
  const whiteCircleLottieRef = useRef();
  const redCircleLottieRef = useRef();

  // white circle
  const [{
    playSegments: playSegmentsWhite,
    stop: stopWhite,
  }] =
    useLottie(
      whiteCircleLottieRef,
      whiteCircle,
      { autoplay: false },
    );
  // red circle
  const [{
    playSegments: playSegmentsRed,
    stop: stopRed,
  }] =
    useLottie(
      redCircleLottieRef,
      redCircle,
      { autoplay: false },
    );
  // chess
  const chessRef = useChessLottie(active, true);

  // play white circle
  useEffect(() => {
    const whiteActive = type === 1 && active;
    if (whiteActive) {
      playSegmentsWhite([[1, 120], [121, 240]]);
    } else {
      stopWhite();
    }
  }, [active, type, playSegmentsWhite, stopWhite]);

  // play red circle
  useEffect(() => {
    const redActive = type === 2 && active;
    if (redActive) {
      playSegmentsRed([[1, 120], [121, 240]]);
    } else {
      stopRed();
    }
  }, [active, type, playSegmentsRed, stopRed]);

  useEffect(() => {
    const setWidth = () => {
      const width = window.innerHeight / 969 * 1920;
      lottieRef.current.style.width = `${width}px`;
    };
    setWidth();
    window.addEventListener('resize', setWidth);
    return () => {
      window.removeEventListener('resize', setWidth);
    };
  }, []);

  return (
    <div className={styles.container}>
      <div className={`${styles.pageTitle} ${active ? styles.active : ''}`} />
      <div className={`${styles.chainLeft} ${active ? styles.active : ''}`} />
      <div className={`${styles.chainRight} ${active ? styles.active : ''}`} />
      <div className={styles.lottie} ref={lottieRef}>
        {/* 中间文案 */}
        <div className={`${styles.worldText} ${type === 1 ? styles.active : ''}`} />
        <div className={`${styles.wordText} ${styles.word1} ${type === 2 && subType === 1 ? styles.active : ''}`} />
        <div className={`${styles.wordText} ${styles.word2} ${type === 2 && subType === 2 ? styles.active : ''}`} />
        <div className={`${styles.wordText} ${styles.word3} ${type === 2 && subType === 3 ? styles.active : ''}`} />
        <div className={`${styles.wordText} ${styles.word4} ${type === 2 && subType === 4 ? styles.active : ''}`} />
        <div className={`${styles.whiteCircle} ${type === 1 ? styles.active : ''}`} ref={whiteCircleLottieRef} />
        {type === 1 && <div className={styles.halfCircle} />}
        <div className={`${styles.redCircle} ${type === 2 ? styles.active : ''}`} ref={redCircleLottieRef} />
        <div className={styles.chess} ref={chessRef} />
      </div>
      <div className={`${styles.worldBtn} ${type === 1 ? styles.active : ''}`} onClick={() => { setType(1); }} />
      <div className={`${styles.wordBtn} ${type === 2 ? styles.active : ''}`} onClick={() => { setType(2); }} />
      {type === 2 &&
        <div className={styles.subBtn}>
          <div
            className={`${styles.subItem1} ${subType === 1 ? styles.active : ''}`}
            onClick={() => { setSubType(1); }}
          >
            <div className={styles.subSelected1} />
            <div className={styles.text} />
          </div>
          <div
            className={`${styles.subItem2} ${subType === 2 ? styles.active : ''}`}
            onClick={() => { setSubType(2); }}
          >
            <div className={styles.subSelected1} />
            <div className={styles.text} />
          </div>
          <div
            className={`${styles.subItem3} ${subType === 3 ? styles.active : ''}`}
            onClick={() => { setSubType(3); }}
          >
            <div className={styles.subSelected2} />
            <div className={styles.text} />
          </div>
          <div
            className={`${styles.subItem4} ${subType === 4 ? styles.active : ''}`}
            onClick={() => { setSubType(4); }}
          >
            <div className={styles.subSelected2} />
            <div className={styles.text} />
          </div>
        </div>}
      <a
        href={xiyueHome}
        target="_blank"
        className={styles.parallelworldBtn}
        rel="noreferrer"
      />
    </div>
  );
};

export default memo(Story);
