/**
 * 新闻公告-PV
 */
import { memo, useEffect, useRef } from 'react';
import { useSwiperSlide, useSwiper } from 'swiper/react';
import usePlayVideo from '@@/hooks/usePlayVideo';
import styles from './style.module.scss';

const NewsPV = ({
  data,
}) => {
  const ref = useRef();
  const swiper = useSwiper();
  const slide = useSwiperSlide();
  const playVideo = usePlayVideo();

  const onPlayVideo = () => {
    playVideo(data.videoUrl);
  };

  useEffect(() => {
    // 先播放2s，防止在首次滚动时黑屏
    ref.current.play().catch(() => {});
    const timer = setTimeout(() => {
      ref.current.pause();
    }, 2000);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const play = (swiper) => {
      if (swiper.realIndex === 1) {
        ref.current.play().catch(() => {});
      }
    };
    const pause = () => {
      ref.current.pause();
    };
    swiper.on('slideChangeTransitionStart', pause);
    swiper.on('slideChangeTransitionEnd', play);

    return () => {
      swiper.off('slideChangeTransitionStart', pause);
      swiper.off('slideChangeTransitionEnd', play);
    };
  }, []);

  return (
    <div className={styles.newsPV}>
      <div className={styles.pvBanner}>
        <video
          className={styles.bannerVideo}
          src={data.previewUrl}
          autoPlay
          loop
          muted
          ref={ref}
        />
        <div className={styles.mask} />
        <div
          className={styles.playBtn}
          onClick={onPlayVideo}
        />
      </div>
      <div className={`${styles.title} ${slide.isActive ? styles.active : ''}`}>
        <div className={styles.titleContent} style={{ backgroundImage: `url('${data.title}')` }} />
      </div>
    </div>
  );
};

export default memo(NewsPV);
