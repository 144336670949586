import Head from 'next/head';
import { store, reducer } from '@@/store/home';
import App from '@@/containers/pc/home';
import '@@/styles/swiper';
import usePreviewPage from '@/components/usePreviewPage';
import HeadSeo from '@/components/head-seo';
import StoreProvider from '@/components/store-provider';
import { getPageData } from '@/model/page';

export default (props) => {
  const { pageData, ...other } = props;
  const data = usePreviewPage(pageData);

  return (
    <>
      <HeadSeo image={data?.page?.share_img?.[0]?.value} />
      <Head>
        <script
          dangerouslySetInnerHTML={{
            __html: `
        document.documentElement.classList.add('pc-app');
        var isIpad = function () {
          var platform = navigator.platform === 'MacIntel' || navigator.platform === 'iPad'
          return platform && navigator.maxTouchPoints > 1
        };
        if (isIpad()) {
          var pathnameArray = window.location.pathname;
          if (pathnameArray.indexOf('/m') === -1) {
            window.location.href =
                window.location.href.split("#")[0]
                + (window.location.search ? '&target=h5' : '?target=h5')
                + window.location.hash;
          }
        }
          `,
          }}
        />
      </Head>
      <StoreProvider store={store} reducer={reducer}>
        <App {...other} {...data} />
      </StoreProvider>
    </>
  );
};

export const getStaticProps = async () => {
  let data = {};
  try {
    data = await getPageData();
  } catch (error) {
    throw new Error(error);
  }
  return {
    props: {
      pageData: data,
    },
  };
};
