import { useState, useRef, useEffect, useCallback, useMemo } from 'react';
import { Mousewheel, Parallax } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import NavBar from '@@/components/NavBar';
import PageLoading from '@@/components/PageLoading';
import Footer from '@@/components/Footer';
import FloatQRCode from '../../../components/FloatQRCode';
import Top from './components/Top';
import NewsPV from './components/NewsPV';
import News from './components/News';
import Character from './components/Character';
import Story from './components/Story';
import Gallery from './components/Gallery';
import Special from './components/Special';
import character1 from './components/Top/img/home_character_1.png';
import character2 from './components/Top/img/home_character_2.png';
import character3 from './components/Top/img/home_character_3.png';
import character4 from './components/Top/img/home_character_4.png';
import character5 from './components/Top/img/home_character_5.png';

import styles from './style.module.scss';

/**
 * add images need to be preloaded here
 * loading progress will be displayed in PageLoading component
 */
const preLoadImages = [
  character1,
  character2,
  character3,
  character4,
  character5,
].map((item) => item.src || item);

const Home = ({ page, banner }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const tempDisableSlideRef = useRef(false);
  const swiperRef = useRef();

  const downloadData = useMemo(() => {
    return {
      dCode: page?.dCode?.[0].value || '',
      dIos: page?.dIos?.[0].value || '',
      dAndroid: page?.dAndroid[0].value || '',
    };
  }, [page]);
  const pvData = useMemo(() => {
    return {
      previewUrl: page?.pv_config?.[0].value || '',
      videoUrl: page?.pv_config?.[2].value || '',
      title: page?.pv_config?.[3].value || '',
    };
  }, [page]);

  const galleryData = useMemo(() => {
    return {
      videoCover: page?.gallery_home_img?.[0]?.value || '',
      cardCover: page?.gallery_home_img?.[1]?.value || '',
      videoUrl: page?.gallery_home_video?.[0]?.value || '',
    };
  }, [page]);

  useEffect(() => {
    const { hash } = window.location;
    if (hash) {
      const index = Number(hash.substring(1));
      if (index) setSelectedIndex(index);
    }
  }, []);

  // get swiper instance
  const onSwiper = useCallback((swiper) => {
    swiperRef.current = swiper;
  }, []);

  // change swiper slide when navbar clicked
  useEffect(() => {
    if (tempDisableSlideRef.current) {
      tempDisableSlideRef.current = false;
      return;
    }
    swiperRef.current.slideTo(selectedIndex);
  }, [selectedIndex]);

  // when swiper change, update active nav
  const onSlideChange = useCallback((swiper) => {
    let { realIndex: index } = swiper;
    index = Math.min(index, 6);
    setSelectedIndex(index);
    window.location.hash = `#${index}`;
  }, [selectedIndex]);

  return (
    <div className={styles.homeContainer}>
      <NavBar
        selectedIndex={selectedIndex}
        setSelectedIndex={setSelectedIndex}
      />
      <PageLoading preLoadImages={preLoadImages} />
      <FloatQRCode url={downloadData.dCode} />
      <Swiper
        speed={600}
        direction="vertical"
        style={{ height: '100vh' }}
        modules={[Mousewheel, Parallax]}
        mousewheel
        slidesPerView="auto"
        allowTouchMove={false}
        onSwiper={onSwiper}
        onSlideChange={onSlideChange}
        parallax
        observeParents
        observer
      >
        <SwiperSlide>
          <Top data={downloadData} />
        </SwiperSlide>
        <SwiperSlide>
          <NewsPV data={pvData} />
        </SwiperSlide>
        <SwiperSlide>
          <News data={banner} />
        </SwiperSlide>
        <SwiperSlide>
          <Character />
        </SwiperSlide>
        <SwiperSlide>
          <Story active={selectedIndex === 4} />
        </SwiperSlide>
        <SwiperSlide>
          <Gallery data={galleryData} />
        </SwiperSlide>
        <SwiperSlide>
          <Special />
        </SwiperSlide>
        <SwiperSlide style={{ height: '27.3vw', overflow: 'visible' }}>
          <Footer />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default Home;
