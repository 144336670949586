import styles from './style.module.scss';

const Footer = ({
  isSecondary,
}) => {
  const year = new Date().getFullYear();

  return (
    <div className={styles.container}>
      <div className={`${styles.iconPic} ${isSecondary ? styles.secondary : ''}`} />
      <div className={styles.iconRow}>
        {/* 官方微信 */}
        <div className={`${styles.iconItem} ${styles.wechat}`}>
          <div className={styles.popup} />
        </div>
        {/* 官方微博 */}
        <a
          href="https://weibo.com/u/3248438592"
          target="_blank"
          className={`${styles.iconItem}
          ${styles.weibo}`}
          rel="noreferrer"
        />
        {/* 官方QQ群 */}
        <div className={`${styles.iconItem} ${styles.qq}`}>
          <div className={styles.popup} />
        </div>
        {/* 在线客服 */}
        <a
          href="https://papegames.qiyukf.com/client?k=a7f4ca2034a3e619ac469ad186c500eb&wp=1&robotShuntSwitch=1&robotId=5247720&gid=264093699&language=zh-cn"
          target="_blank"
          className={`${styles.iconItem} ${styles.service}`}
          rel="noreferrer"
        />
        {/* 恋与同人站 */}
        <a
          href="https://tongren.papegames.cn/"
          target="_blank"
          className={`${styles.iconItem} ${styles.coterie}`}
          rel="noreferrer"
        />
        {/* 官方旗舰店 */}
        <a
          href="https://paperpresented.tmall.com/"
          target="_blank"
          className={`${styles.iconItem} ${styles.store}`}
          rel="noreferrer"
        />
      </div>
      <a className={styles.toCorpHome} href="https://www.papegames.com/" alt="叠纸官网" target="_blank" rel="noreferrer" />
      <div className={styles.links}>
        <a href="/contract" target="_blank">服务协议</a>|
        <a href="/contract?key=privacy" target="_blank">隐私政策</a>|
        <a href="/contract?key=privacySDK" target="_blank">第三方信息共享清单</a>|
        <a href="/contract?key=childrenPrivacy" target="_blank">儿童隐私保护规则</a>
      </div>
      <div className={styles.corpInfo}>
        <p>著作权人：芜湖叠纸网络科技有限公司&nbsp;
          <a href="http://beian.miit.gov.cn/" target="_blank" rel="noreferrer">皖B2-20170073-3</a>&nbsp;
          文网游备字 [2017] M-RPG 1512 号&nbsp;著作登记号：2017SR468901&nbsp;
          <a
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=34020702000518"
            target="_blank"
            rel="noreferrer"
          >
            <img src="https://static.papegames.com/nikkiweb/static/beian.png" />
            皖公网安备 34020702000518号
          </a>
        </p>
        <p>出版服务单位：北京中清龙图网络技术有限公司新广出审[2017]8847号 ISBN 978-7-498-01805-2</p>
        <p>COPYRIGHT © 2013-{year} WUHU NIKKI CO., Ltd. ALL RIGHTS RESERVED.</p>
      </div>
    </div>
  );
};

Footer.defaultProps = {
  isSecondary: false,
};

export default Footer;
