import { memo } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { newsBySection } from '@@/service';
import { usePagination } from 'ahooks';
import NewsList from '@@/components/NewsList';
import Pagination from '@/src/components/Pagination';
import { NewsFetchType } from '../../constants';
import styles from './style.module.scss';

const pageSize = 9;

export async function fetchData(params) {
  const { current, pageSize, type } = params;
  const res = await newsBySection(NewsFetchType[type], (current - 1) * pageSize, pageSize);
  return {
    total: res.total,
    list: res.data || [],
  };
}

const MoreNewsList = ({
  onClose,
  type,
}) => {
  const { data, pagination: { total, current, changeCurrent } } = usePagination(
    ({ current, pageSize }) => {
      return fetchData({
        current,
        pageSize,
        type,
      });
    },
    {
      refreshDeps: [type],
      defaultPageSize: pageSize,
    },
  );

  return (
    <div className={styles.newsListWrap}>
      <div
        className={styles.modalClose}
        onClick={onClose}
      />
      <SwitchTransition mode="out-in">
        <CSSTransition key={`${type}-${current}`} timeout={300} classNames="parallex">
          <NewsList data={data?.list || []} />
        </CSSTransition>
      </SwitchTransition>
      <Pagination
        className={styles.pagination}
        total={total}
        page={current}
        pageSize={pageSize}
        onChange={(val) => {
          changeCurrent(val);
        }}
      />
    </div>
  );
};

export default memo(MoreNewsList);
