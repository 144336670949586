const assets = [];
assets.push([require("./images/img_0.png").default ? require("./images/img_0.png").default: require("./images/img_0.png"), 0]);
assets.push([require("./images/img_1.png").default ? require("./images/img_1.png").default: require("./images/img_1.png"), 1]);
assets.push([require("./images/img_2.png").default ? require("./images/img_2.png").default: require("./images/img_2.png"), 2]);
assets.push([require("./images/img_3.png").default ? require("./images/img_3.png").default: require("./images/img_3.png"), 3]);
assets.push([require("./images/img_4.png").default ? require("./images/img_4.png").default: require("./images/img_4.png"), 4]);
assets.push([require("./images/img_5.png").default ? require("./images/img_5.png").default: require("./images/img_5.png"), 5]);
assets.push([require("./images/img_6.png").default ? require("./images/img_6.png").default: require("./images/img_6.png"), 6]);
assets.push([require("./images/img_7.png").default ? require("./images/img_7.png").default: require("./images/img_7.png"), 7]);
assets.push([require("./images/img_8.png").default ? require("./images/img_8.png").default: require("./images/img_8.png"), 8]);
assets.push([require("./images/img_9.png").default ? require("./images/img_9.png").default: require("./images/img_9.png"), 9]);
assets.push([require("./images/img_10.png").default ? require("./images/img_10.png").default: require("./images/img_10.png"), 10]);
assets.push([require("./images/img_11.jpg").default ? require("./images/img_11.jpg").default: require("./images/img_11.jpg"), 11]);
assets.push([require("./images/img_12.jpg").default ? require("./images/img_12.jpg").default: require("./images/img_12.jpg"), 12]);
assets.push([require("./images/img_13.jpg").default ? require("./images/img_13.jpg").default: require("./images/img_13.jpg"), 13]);
assets.push([require("./images/img_14.png").default ? require("./images/img_14.png").default: require("./images/img_14.png"), 14]);
assets.push([require("./images/img_15.png").default ? require("./images/img_15.png").default: require("./images/img_15.png"), 15]);
assets.push([require("./images/img_16.png").default ? require("./images/img_16.png").default: require("./images/img_16.png"), 16]);
const data = {
  "v": "5.7.14",
  "fr": 30,
  "ip": 0,
  "op": 450,
  "w": 1920,
  "h": 969,
  "nm": "Img",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 1598,
      "h": 353,
      "u": "",
      "p": "img_0.png",
      "e": 0
    },
    {
      "id": "image_1",
      "w": 1618,
      "h": 292,
      "u": "",
      "p": "img_1.png",
      "e": 0
    },
    {
      "id": "image_2",
      "w": 1630,
      "h": 258,
      "u": "",
      "p": "img_2.png",
      "e": 0
    },
    {
      "id": "image_3",
      "w": 116,
      "h": 115,
      "u": "",
      "p": "img_3.png",
      "e": 0
    },
    {
      "id": "image_4",
      "w": 116,
      "h": 115,
      "u": "",
      "p": "img_4.png",
      "e": 0
    },
    {
      "id": "image_5",
      "w": 52,
      "h": 52,
      "u": "",
      "p": "img_5.png",
      "e": 0
    },
    {
      "id": "image_6",
      "w": 52,
      "h": 52,
      "u": "",
      "p": "img_6.png",
      "e": 0
    },
    {
      "id": "image_7",
      "w": 52,
      "h": 52,
      "u": "",
      "p": "img_7.png",
      "e": 0
    },
    {
      "id": "image_8",
      "w": 52,
      "h": 52,
      "u": "",
      "p": "img_8.png",
      "e": 0
    },
    {
      "id": "image_9",
      "w": 52,
      "h": 52,
      "u": "",
      "p": "img_9.png",
      "e": 0
    },
    {
      "id": "image_10",
      "w": 122,
      "h": 121,
      "u": "",
      "p": "img_10.png",
      "e": 0
    },
    {
      "id": "image_11",
      "w": 1920,
      "h": 969,
      "u": "",
      "p": "img_11.jpg",
      "e": 0
    },
    {
      "id": "image_12",
      "w": 1920,
      "h": 969,
      "u": "",
      "p": "img_12.jpg",
      "e": 0
    },
    {
      "id": "image_13",
      "w": 1920,
      "h": 969,
      "u": "",
      "p": "img_13.jpg",
      "e": 0
    },
    {
      "id": "image_14",
      "w": 60,
      "h": 60,
      "u": "",
      "p": "img_14.png",
      "e": 0
    },
    {
      "id": "image_15",
      "w": 60,
      "h": 60,
      "u": "",
      "p": "img_15.png",
      "e": 0
    },
    {
      "id": "image_16",
      "w": 80,
      "h": 80,
      "u": "",
      "p": "img_16.png",
      "e": 0
    },
    {
      "id": "comp_0",
      "nm": "组 263 拷贝",
      "layers": [
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "star-light 拷贝 14",
          "refId": "image_3",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                1491,
                476.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                58,
                57.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 452,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "star-light 拷贝 13",
          "refId": "image_4",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                1491,
                476.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                58,
                57.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "sy": [
            {
              "c": {
                "a": 0,
                "k": [
                  0.960784912109,
                  0.741180419922,
                  0.501953125,
                  1
                ],
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 74,
                "ix": 3
              },
              "a": {
                "a": 0,
                "k": 90,
                "ix": 5
              },
              "s": {
                "a": 0,
                "k": 51,
                "ix": 8
              },
              "d": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "ch": {
                "a": 0,
                "k": 0,
                "ix": 7
              },
              "bm": {
                "a": 0,
                "k": 1,
                "ix": 1
              },
              "no": {
                "a": 0,
                "k": 0,
                "ix": 9
              },
              "lc": {
                "a": 0,
                "k": 1,
                "ix": 10
              },
              "ty": 1,
              "nm": "投影"
            },
            {
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 5
              },
              "o": {
                "a": 0,
                "k": 11,
                "ix": 2
              },
              "s": {
                "a": 0,
                "k": 10,
                "ix": 10
              },
              "r": {
                "a": 0,
                "k": 50,
                "ix": 11
              },
              "ch": {
                "a": 0,
                "k": 0,
                "ix": 9
              },
              "bm": {
                "a": 0,
                "k": 1,
                "ix": 1
              },
              "no": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "j": {
                "a": 0,
                "k": 0,
                "ix": 12
              },
              "ty": 3,
              "nm": "外发光"
            }
          ],
          "ip": 0,
          "op": 452,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_1",
      "nm": "组 263 拷贝 2",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          "nm": "star-light 拷贝 15",
          "refId": "image_5",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                285,
                364,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 451,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "star-light 拷贝 14",
          "refId": "image_6",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                285,
                364,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 451,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "star-light 拷贝 13",
          "refId": "image_7",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                285,
                364,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "sy": [
            {
              "c": {
                "a": 0,
                "k": [
                  0.960784912109,
                  0.741180419922,
                  0.501953125,
                  1
                ],
                "ix": 2
              },
              "o": {
                "a": 0,
                "k": 74,
                "ix": 3
              },
              "a": {
                "a": 0,
                "k": 90,
                "ix": 5
              },
              "s": {
                "a": 0,
                "k": 51,
                "ix": 8
              },
              "d": {
                "a": 0,
                "k": 0,
                "ix": 6
              },
              "ch": {
                "a": 0,
                "k": 0,
                "ix": 7
              },
              "bm": {
                "a": 0,
                "k": 1,
                "ix": 1
              },
              "no": {
                "a": 0,
                "k": 0,
                "ix": 9
              },
              "lc": {
                "a": 0,
                "k": 1,
                "ix": 10
              },
              "ty": 1,
              "nm": "投影"
            },
            {
              "c": {
                "a": 0,
                "k": [
                  1,
                  1,
                  1,
                  1
                ],
                "ix": 5
              },
              "o": {
                "a": 0,
                "k": 11,
                "ix": 2
              },
              "s": {
                "a": 0,
                "k": 10,
                "ix": 10
              },
              "r": {
                "a": 0,
                "k": 50,
                "ix": 11
              },
              "ch": {
                "a": 0,
                "k": 0,
                "ix": 9
              },
              "bm": {
                "a": 0,
                "k": 1,
                "ix": 1
              },
              "no": {
                "a": 0,
                "k": 0,
                "ix": 3
              },
              "j": {
                "a": 0,
                "k": 0,
                "ix": 12
              },
              "ty": 3,
              "nm": "外发光"
            }
          ],
          "ip": 0,
          "op": 451,
          "st": 0,
          "bm": 0
        }
      ]
    },
    {
      "id": "comp_2",
      "nm": "组 263",
      "layers": [
        {
          "ddd": 0,
          "ind": 1,
          "ty": 2,
          "nm": "star-light 拷贝 15",
          "refId": "image_8",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                1643,
                322,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 451,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 2,
          "ty": 2,
          "nm": "star-light 拷贝 14",
          "refId": "image_9",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                1643,
                322,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                26,
                26,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 451,
          "st": 0,
          "bm": 0
        },
        {
          "ddd": 0,
          "ind": 3,
          "ty": 2,
          "nm": "组 271",
          "refId": "image_10",
          "sr": 1,
          "ks": {
            "o": {
              "a": 0,
              "k": 100,
              "ix": 11
            },
            "r": {
              "a": 0,
              "k": 0,
              "ix": 10
            },
            "p": {
              "a": 0,
              "k": [
                1642,
                321.5,
                0
              ],
              "ix": 2,
              "l": 2
            },
            "a": {
              "a": 0,
              "k": [
                61,
                60.5,
                0
              ],
              "ix": 1,
              "l": 2
            },
            "s": {
              "a": 0,
              "k": [
                100,
                100,
                100
              ],
              "ix": 6,
              "l": 2
            }
          },
          "ao": 0,
          "ip": 0,
          "op": 451,
          "st": 0,
          "bm": 0
        }
      ]
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 1,
      "ty": 2,
      "nm": "组 265.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 6,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 30,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 45,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                19
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 139,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 184,
              "s": [
                19
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 227,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 272,
              "s": [
                19
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 315,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 360,
              "s": [
                19
              ]
            },
            {
              "t": 403,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 30,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 45,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 173,
              "s": [
                13.2
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 303,
              "s": [
                2.8
              ]
            },
            {
              "t": 449,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            970.5,
            342.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            799,
            176.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                177,
                177,
                100
              ]
            },
            {
              "t": 30,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 451,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 2,
      "ty": 2,
      "nm": "组 269.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 10,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 34,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 139,
              "s": [
                30
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 184,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 227,
              "s": [
                30
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 272,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 315,
              "s": [
                30
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 360,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 403,
              "s": [
                30
              ]
            },
            {
              "t": 448,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 34,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 45,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 173,
              "s": [
                2.7
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 303,
              "s": [
                5.2
              ]
            },
            {
              "t": 449,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            970.5,
            342.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            809,
            146,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 4,
              "s": [
                177,
                177,
                100
              ]
            },
            {
              "t": 34,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 451,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 3,
      "ty": 2,
      "nm": "组 270.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 14,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 38,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 45,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 90,
              "s": [
                19
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 139,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 184,
              "s": [
                19
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 227,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 272,
              "s": [
                19
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 315,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 360,
              "s": [
                19
              ]
            },
            {
              "t": 403,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 38,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 45,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 173,
              "s": [
                -6.6
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0
                ]
              },
              "t": 303,
              "s": [
                8.4
              ]
            },
            {
              "t": 449,
              "s": [
                0
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            970.5,
            342.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            815,
            129,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 8,
              "s": [
                177,
                177,
                100
              ]
            },
            {
              "t": 38,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 451,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 4,
      "ty": 0,
      "nm": "组 263 拷贝",
      "refId": "comp_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.18
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 45,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 45,
              "s": [
                0
              ]
            },
            {
              "t": 449,
              "s": [
                -360
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 45,
              "s": [
                1520.5,
                474,
                0
              ],
              "to": [
                -7.833,
                -3,
                0
              ],
              "ti": [
                8,
                -5.25,
                0
              ]
            },
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 173,
              "s": [
                1473.5,
                456,
                0
              ],
              "to": [
                -8,
                5.25,
                0
              ],
              "ti": [
                -7.833,
                -3,
                0
              ]
            },
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 303,
              "s": [
                1472.5,
                505.5,
                0
              ],
              "to": [
                7.833,
                3,
                0
              ],
              "ti": [
                -8,
                5.25,
                0
              ]
            },
            {
              "t": 449,
              "s": [
                1520.5,
                474,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            1491,
            476.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 45,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 1920,
      "h": 969,
      "ip": 0,
      "op": 450,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 0,
      "nm": "组 263 拷贝 2",
      "refId": "comp_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.18
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 45,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 45,
              "s": [
                0
              ]
            },
            {
              "t": 449,
              "s": [
                360
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 45,
              "s": [
                270.287,
                367.999,
                0
              ],
              "to": [
                5.708,
                -5.25,
                0
              ],
              "ti": [
                4.5,
                5.958,
                0
              ]
            },
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 173,
              "s": [
                304.537,
                336.499,
                0
              ],
              "to": [
                -4.5,
                -5.958,
                0
              ],
              "ti": [
                5.708,
                -5.25,
                0
              ]
            },
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 303,
              "s": [
                243.287,
                332.249,
                0
              ],
              "to": [
                -5.708,
                5.25,
                0
              ],
              "ti": [
                -4.5,
                -5.958,
                0
              ]
            },
            {
              "t": 449,
              "s": [
                270.287,
                367.999,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            285,
            364,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 45,
              "s": [
                82.435,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 1920,
      "h": 969,
      "ip": 0,
      "op": 450,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 0,
      "nm": "组 263",
      "refId": "comp_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.18
                ],
                "y": [
                  1
                ]
              },
              "o": {
                "x": [
                  0.333
                ],
                "y": [
                  0
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "t": 45,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 45,
              "s": [
                0
              ]
            },
            {
              "t": 449,
              "s": [
                360
              ]
            }
          ],
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 45,
              "s": [
                1642,
                321.5,
                0
              ],
              "to": [
                6.667,
                -5.833,
                0
              ],
              "ti": [
                2.917,
                7.667,
                0
              ]
            },
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 173,
              "s": [
                1682,
                286.5,
                0
              ],
              "to": [
                -2.917,
                -7.667,
                0
              ],
              "ti": [
                6.667,
                -5.833,
                0
              ]
            },
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 303,
              "s": [
                1624.5,
                275.5,
                0
              ],
              "to": [
                -6.667,
                5.833,
                0
              ],
              "ti": [
                -2.917,
                -7.667,
                0
              ]
            },
            {
              "t": 449,
              "s": [
                1642,
                321.5,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            1642,
            321.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0,
                  0,
                  0.667
                ],
                "y": [
                  1,
                  1,
                  1
                ]
              },
              "o": {
                "x": [
                  0.333,
                  0.333,
                  0.333
                ],
                "y": [
                  0,
                  0,
                  0
                ]
              },
              "t": 0,
              "s": [
                0,
                0,
                100
              ]
            },
            {
              "t": 45,
              "s": [
                100,
                100,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "w": 1920,
      "h": 969,
      "ip": 0,
      "op": 450,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "椭圆 953 拷贝 3",
      "refId": "image_11",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "hasMask": true,
      "masksProperties": [
        {
          "inv": false,
          "mode": "a",
          "pt": {
            "a": 0,
            "k": {
              "i": [
                [
                  -1.933,
                  0
                ],
                [
                  0,
                  -1.933
                ],
                [
                  1.933,
                  0
                ],
                [
                  0,
                  1.933
                ]
              ],
              "o": [
                [
                  1.933,
                  0
                ],
                [
                  0,
                  1.933
                ],
                [
                  -1.933,
                  0
                ],
                [
                  0,
                  -1.933
                ]
              ],
              "v": [
                [
                  515,
                  444.5
                ],
                [
                  518.5,
                  448
                ],
                [
                  515,
                  451.5
                ],
                [
                  511.5,
                  448
                ]
              ],
              "c": true
            },
            "ix": 1
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 3
          },
          "x": {
            "a": 0,
            "k": 0,
            "ix": 4
          },
          "nm": "蒙版 1"
        }
      ],
      "ip": 0,
      "op": 452,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 2,
      "nm": "椭圆 953 拷贝 4",
      "refId": "image_12",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "hasMask": true,
      "masksProperties": [
        {
          "inv": false,
          "mode": "a",
          "pt": {
            "a": 0,
            "k": {
              "i": [
                [
                  -1.933,
                  0
                ],
                [
                  0,
                  -1.933
                ],
                [
                  1.933,
                  0
                ],
                [
                  0,
                  1.933
                ]
              ],
              "o": [
                [
                  1.933,
                  0
                ],
                [
                  0,
                  1.933
                ],
                [
                  -1.933,
                  0
                ],
                [
                  0,
                  -1.933
                ]
              ],
              "v": [
                [
                  1259,
                  219.5
                ],
                [
                  1262.5,
                  223
                ],
                [
                  1259,
                  226.5
                ],
                [
                  1255.5,
                  223
                ]
              ],
              "c": true
            },
            "ix": 1
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 3
          },
          "x": {
            "a": 0,
            "k": 0,
            "ix": 4
          },
          "nm": "蒙版 1"
        }
      ],
      "ip": 0,
      "op": 452,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 2,
      "nm": "椭圆 953 拷贝 5",
      "refId": "image_13",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "hasMask": true,
      "masksProperties": [
        {
          "inv": false,
          "mode": "a",
          "pt": {
            "a": 0,
            "k": {
              "i": [
                [
                  -2.761,
                  0
                ],
                [
                  0,
                  -2.761
                ],
                [
                  2.761,
                  0
                ],
                [
                  0,
                  2.761
                ]
              ],
              "o": [
                [
                  2.761,
                  0
                ],
                [
                  0,
                  2.761
                ],
                [
                  -2.761,
                  0
                ],
                [
                  0,
                  -2.761
                ]
              ],
              "v": [
                [
                  376,
                  414
                ],
                [
                  381,
                  419
                ],
                [
                  376,
                  424
                ],
                [
                  371,
                  419
                ]
              ],
              "c": true
            },
            "ix": 1
          },
          "o": {
            "a": 0,
            "k": 100,
            "ix": 3
          },
          "x": {
            "a": 0,
            "k": 0,
            "ix": 4
          },
          "nm": "蒙版 1"
        }
      ],
      "ip": 0,
      "op": 452,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 2,
      "nm": "组 266",
      "refId": "image_14",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            515,
            448,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            30,
            30,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 452,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 2,
      "nm": "组 267",
      "refId": "image_15",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1259,
            223,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            30,
            30,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 452,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 2,
      "nm": "组 268",
      "refId": "image_16",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            376,
            419,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            40,
            40,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 452,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}

assets.forEach((asset) => {
  const path = typeof asset[0] === 'string' ? asset[0] : asset[0].src;
  const index = asset[1];
  data.assets[index].p = path;
});
module.exports = data;