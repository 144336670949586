/* eslint-disable jsx-quotes */
import { useEffect, useState, useCallback } from 'react';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { usePlayVideo, useChessLottie } from '@@/hooks';
import VideoGallery from './components/VideoGallery';
import CardGallery from './components/CardGallery';
import MmdGallery from './components/MmdGallery';

import { DisplayType } from './constants';

import styles from './style.module.scss';

const Gallery = ({ data }) => {
  const [displayType, setDisplayType] = useState(DisplayType.首屏);
  const swiper = useSwiper();
  const { isActive } = useSwiperSlide();

  const chessRef = useChessLottie(isActive);

  useEffect(() => {
    swiper.on('slideChange', () => {
      swiper.mousewheel.enable();
      setDisplayType(DisplayType.首屏);
    });
  }, [swiper]);

  const playVideo = usePlayVideo();

  const onClickVideoList = () => {
    swiper.mousewheel.disable();
    setDisplayType(DisplayType.视频);
  };

  const onClose = useCallback(() => {
    swiper.mousewheel.enable();
    setDisplayType(DisplayType.首屏);
  }, [swiper]);

  const onClickCardList = () => {
    swiper.mousewheel.disable();
    setDisplayType(DisplayType.羁绊);
  };

  const onClickMmdList = () => {
    swiper.mousewheel.disable();
    setDisplayType(DisplayType.mmd);
  };

  return (
    <div className={styles.container}>
      <SwitchTransition mode='out-in'>
        <CSSTransition key={displayType} timeout={200} classNames='fade'>
          <>
            {/* 视听站首屏 */}
            {displayType === DisplayType.首屏 && (
              <div>
                <div style={{ height: '100vh' }} ref={chessRef} />
                <div className={styles.galleryBg} data-swiper-parallax='-200' data-swiper-parallax-duration='1200' />
                <div className={`${styles.pageTitle} ${isActive ? styles.active : ''}`} />
                <div className={styles.panel} data-swiper-parallax='-200' data-swiper-parallax-duration='1200' />
                <div className={`${styles.title} ${styles.pvTitle} ${isActive ? styles.active : ''}`}>
                  <div className={styles.titleContent} />
                </div>
                <div className={`${styles.title} ${styles.cardTitle} ${isActive ? styles.active : ''}`}>
                  <div className={styles.titleContent} />
                </div>
                <div className={`${styles.title} ${styles.mmdTitle} ${isActive ? styles.active : ''}`}>
                  <div className={styles.titleContent} />
                </div>
                <div
                  className={styles.video}
                  style={{ backgroundImage: `url('${data.videoCover}')` }}
                  data-swiper-parallax='-200'
                  data-swiper-parallax-duration='1200'
                >
                  <div className={styles.hoverCover}>
                    <div
                      className={styles.playBtn}
                      onClick={() => {
                        playVideo(data.videoUrl);
                      }}
                    />
                    <div className={styles.seeMoreBtn} onClick={onClickVideoList} />
                  </div>
                </div>
                <div
                  className={styles.card}
                  style={{ backgroundImage: `url('${data.cardCover}')` }}
                  data-swiper-parallax='-200'
                  data-swiper-parallax-duration='1200'
                >
                  <div className={styles.hoverCover}>
                    <div className={styles.seeMoreBtn} onClick={onClickCardList} />
                  </div>
                </div>
                <div
                  className={styles.mmd}
                  // style={{ backgroundImage: `url('${data.cardCover}')` }}
                  data-swiper-parallax='-200'
                  data-swiper-parallax-duration='1200'
                >
                  <div className={styles.hoverCover}>
                    <div className={styles.seeMoreBtn} onClick={onClickMmdList} />
                  </div>
                </div>
              </div>
            )}
            {/* 视频 */}
            {displayType === DisplayType.视频 && <VideoGallery onClose={onClose} />}
            {/* 羁绊 */}
            {displayType === DisplayType.羁绊 && <CardGallery onClose={onClose} />}
            {/* mmd */}
            {displayType === DisplayType.mmd && <MmdGallery onClose={onClose} />}
          </>
        </CSSTransition>
      </SwitchTransition>
    </div>
  );
};

export default Gallery;
