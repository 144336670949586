import { useCallback } from 'react';
import styles from './style.module.scss';

const Pagination = ({
  page,
  pageSize,
  total,
  onChange,
  className,
  ...props
}) => {
  const totalPage = Math.ceil(total / pageSize);
  const onChangePage = useCallback((e) => {
    const { key } = e.currentTarget.dataset;
    switch (key) {
      case 'first':
        if (page === 1) return;
        onChange(1);
        break;
      case 'prev':
        if (page === 1) return;
        onChange(page - 1);
        break;
      case 'next':
        if (page === totalPage) return;
        onChange(page + 1);
        break;
      case 'last':
        if (page === totalPage) return;
        onChange(totalPage);
        break;
      default:
        break;
    }
  }, [onChange, page, totalPage]);

  return (
    <div {...props} className={`${styles.wrap} ${className}`}>
      <div
        className={`${styles.btn} ${styles.first} ${page === 1 ? styles.disabled : ''}`}
        data-key="first"
        onClick={onChangePage}
      />
      <div
        className={`${styles.btn} ${styles.prev} ${page === 1 ? styles.disabled : ''}`}
        data-key="prev"
        onClick={onChangePage}
      />
      <div className={styles.pageBox}>
        {page}/{totalPage}
      </div>
      <div
        className={`${styles.btn} ${styles.next} ${page === totalPage ? styles.disabled : ''}`}
        data-key="next"
        onClick={onChangePage}
      />
      <div
        className={`${styles.btn} ${styles.last} ${page === totalPage ? styles.disabled : ''}`}
        data-key="last"
        onClick={onChangePage}
      />
    </div>
  );
};

export default Pagination;
