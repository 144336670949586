import { useState, useCallback, useRef } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useStore } from '@@/store';
import lottiePlayCV from '@@/animation/lottiePlayCV/data.json';
import PlayBtn from '@@/components/PlayBtn';
import styles from './style.module.scss';

const CharacterDetail = ({
  initialIndex,
  onClose,
}) => {
  const swiperRef = useRef();
  const [activeNav, setActiveNav] = useState(0); // switch between 2 pages of character description
  const { store: { sounds: [soundsAction, soundsStatus] } } = useStore();

  const onSwiper = useCallback((swiper) => {
    swiperRef.current = swiper;
  }, []);

  const onSlideChange = useCallback(() => {
    setActiveNav(0);
  }, []);

  const onClickChangeSlide = (direction) => {
    const swiper = swiperRef.current;
    if (direction === 'prev') {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  const handleClose = () => {
    const { realIndex } = swiperRef.current;
    soundsAction.pause(`cvMusic${realIndex + 1}`, 'stop');
    soundsAction.pause(`roleMusic${realIndex + 1}`, 'stop');
    onClose();
  };

  return (
    <div className={styles.container}>
      <Swiper
        style={{ height: '100%' }}
        slidesPerView="auto"
        onSwiper={onSwiper}
        onSlideChange={onSlideChange}
        initialSlide={initialIndex}
        allowTouchMove={false}
        loop
      >
        {/* 背景图 */}
        {new Array(5).fill('').map((_, index) => (
          <SwiperSlide key={index}>
            <div
              className={`${styles.characterBg} character${index + 1}`}
            >
              <div className={styles.prev} onClick={() => { onClickChangeSlide('prev'); }} />
              <div className={styles.next} onClick={() => { onClickChangeSlide('next'); }} />
              <div className={styles.closeBtn} onClick={handleClose} />
              <div className={styles.evolLove} />
              <div className={styles.starLeft} />
              <div className={styles.starRight} />
              <div className={styles.name} />
              <div className={styles.sign} />
              <div className={styles.character} />
              <div className={styles.characterShadow} />
              <div className={styles.description}>
                <div className={`${styles.infoP1} ${activeNav === 0 ? styles.active : ''}`} />
                <div className={`${styles.infoP2} ${activeNav === 1 ? styles.active : ''}`} />
                <div className={styles.infoNav}>
                  <div
                    className={`${styles.navItem} ${activeNav === 0 ? styles.active : ''}`}
                    onClick={() => { setActiveNav(0); }}
                  />
                  <div
                    className={`${styles.navItem} ${activeNav === 1 ? styles.active : ''}`}
                    onClick={() => { setActiveNav(1); }}
                  />
                </div>
              </div>
              <div className={styles.cv} />
              <PlayBtn className={styles.playVoiceBtn} name={`cvMusic${index + 1}`} lottie={lottiePlayCV} />
              <div className={styles.roleMusic} />
              <PlayBtn className={styles.playMusicBtn} name={`roleMusic${index + 1}`} />
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CharacterDetail;
