import { memo, useRef, useCallback } from 'react';
import { Swiper, SwiperSlide, useSwiperSlide } from 'swiper/react';
import { EffectCoverflow, Pagination, Autoplay } from 'swiper';
import { useChessLottie } from '@/src/hooks';

import styles from './style.module.scss';

const Special = () => {
  const slide = useSwiperSlide();
  const chessRef = useChessLottie(slide.isActive);
  const swiperRef = useRef();
  const onSwiper = useCallback((swiper) => {
    swiperRef.current = swiper;
  }, []);

  const onClickChangeSlide = (direction) => {
    const { current: swiper } = swiperRef;
    if (direction === 'prev') {
      swiper.slidePrev();
    } else {
      swiper.slideNext();
    }
  };

  return (
    <div className={styles.container}>
      <div className={`${styles.pageTitle} ${slide.isActive ? styles.active : ''}`} />
      <div ref={chessRef} className={styles.chess} />
      <div className={styles.galleryBg} />
      <div className={styles.prev} onClick={() => { onClickChangeSlide('prev'); }} />
      <div className={styles.next} onClick={() => { onClickChangeSlide('next'); }} />
      <Swiper
        className={styles.swiper}
        onSwiper={onSwiper}
        effect="coverflow"
        centeredSlides
        grabCursor
        coverflowEffect={{
          rotate: 5, // 旋转的角度
          stretch: 560, // 拉伸   图片间左右的间距和密集度
          depth: 200, // 深度   切换图片间上下的间距和密集度
          modifier: 1, // 修正值 该值越大前面的效果越明显
          slideShadows: false, // 页面阴影效果
        }}
        modules={[Autoplay, EffectCoverflow, Pagination]}
        autoplay={{
          delay: 3000,
          disableOnInteraction: false,
        }}
        loop
        speed={800}
        allowTouchMove
        loopAdditionalSlides={2}
        slidesPerView={1}
        pagination={{
          horizontalClass: 'navBar',
          bulletClass: 'tab',
          bulletActiveClass: 'cur',
          clickable: true,
          renderBullet(index, className) {
            return (
              `<span class="${className}"></span>`
            );
          },
        }}
      >
        <SwiperSlide>
          <div className={`${styles.bannerItem} ${styles.banner1}`} />
        </SwiperSlide>
        <SwiperSlide>
          <div className={`${styles.bannerItem} ${styles.banner2}`} />
        </SwiperSlide>
        <SwiperSlide>
          <div className={`${styles.bannerItem} ${styles.banner3}`} />
        </SwiperSlide>
        <SwiperSlide>
          <div className={`${styles.bannerItem} ${styles.banner4}`} />
        </SwiperSlide>
        <SwiperSlide>
          <div className={`${styles.bannerItem} ${styles.banner5}`} />
        </SwiperSlide>
      </Swiper>
    </div>
  );
};

export default memo(Special);
