import { useCallback } from 'react';
import Image from 'next/image';
import { convertDXToVW } from '@@/utils';
import { useMultiSound } from '@@/hooks';
import { multiSoundConfig } from '@/src/config';
import Logo from './img/common_logo.png';
import styles from './style.module.scss';
import { navListData } from './config';

const NavBar = ({
  selectedIndex,
  setSelectedIndex,
  external,
}) => {
  const [soundsAction, soundsStatus] = useMultiSound(multiSoundConfig);

  const onSwitchPlay = () => {
    if (soundsStatus['bg']) {
      soundsAction.pause('bg');
    } else {
      soundsAction.play('bg');
    }
  };

  const onClickNav = (item, index) => {
    if (item.url) {
      window.open(item.url);
      return;
    }
    if (external) {
      let link = `/home#${index}`;
      if (window.location.pathname.startsWith('/m/')) {
        link = `/m/${link}`;
      }
      window.location.assign(link);
      return;
    }
    setSelectedIndex(index);
  };

  return (
    <div className={styles.navBar}>
      <div className={styles.logo} onClick={() => { setSelectedIndex(0); }}>
        <Image src={Logo} alt="logo" />
      </div>
      <div className={styles.navRight}>
        <div className={styles.navList}>
          {navListData.map((item, index) => {
            index = index === 0 ? 0 : index + 1;
            const isPVSlide = index === 2 && selectedIndex === 1;
            return (
              <div
                key={item.key}
                className={`${styles.navItem} ${selectedIndex === index || isPVSlide ? `${styles.active}` : ''}`}
                style={{ width: convertDXToVW(item.width) }}
                onClick={() => onClickNav(item, index)}
              >
                <div
                  className={`${styles.navLogo} ${item.key}`}
                />
              </div>
            );
          })}
        </div>
        <div
          className={`${styles.audioBtn} ${soundsStatus?.bg ? styles.active : ''}`}
          onClick={onSwitchPlay}
        />
      </div>
    </div>
  );
};

NavBar.defaultProps = {
  selectedIndex: 0,
  external: false,
  setSelectedIndex: () => { },
};

export default NavBar;
