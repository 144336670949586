/* eslint-disable jsx-quotes */

import styles from './style.module.scss';

const GalleryItem = ({ type, title, previewSrc, src }) => {
  return (
    <div className={styles.item} style={{ backgroundImage: `url('${previewSrc}')` }}>
      <a href={src || '#'}>
        <div className={`${styles.cover} ${styles.mmd} `}>
          <div className={styles.mmdBtn} />

          <div className={styles.title}>{title}</div>
        </div>
      </a>
    </div>
  );
};

export default GalleryItem;
