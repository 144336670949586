/**
 * 首页模块
 */
import { memo, useRef, useCallback, useState, useEffect } from 'react';
import { Autoplay, EffectFade } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Modal } from '@diezhi/yesio';
import AgeTipModal from './AgeTipModal';
import styles from './style.module.scss';

const Top = ({
  data,
}) => {
  const swiperRef = useRef();
  const modalRef = useRef();
  const [activeIndex, setActiveIndex] = useState(0); // 当前角色幻灯片索引
  const [showScrollTip, setShowScrollTip] = useState(false); // 是否显示滚动提示
  const onSwiper = useCallback((swiper) => {
    swiperRef.current = swiper;
  }, []);

  const onSlideChange = useCallback((swiper) => {
    setActiveIndex(swiper.realIndex);
  }, []);

  const onCharacterChange = useCallback((e) => {
    const index = Number(e.currentTarget.getAttribute('index'));
    setActiveIndex(index);
    swiperRef.current.slideTo(index);
  }, []);

  const showAgeTip = () => {
    modalRef.current = Modal.show({
      content:
      (<AgeTipModal modalRef={modalRef} />),
      closeOnMaskClick: true,
    });
  };

  useEffect(() => {
    if (showScrollTip) return;
    const timer = setTimeout(() => {
      setShowScrollTip(true);
    }, 3000);
    return () => {
      clearTimeout(timer);
    };
  }, [showScrollTip]);

  return (
    <div className={styles.topSwiperWrap}>
      <Swiper
        style={{ height: '100vh' }}
        modules={[Autoplay, EffectFade]}
        effect="fade"
        slidesPerView="auto"
        allowTouchMove={false}
        onSwiper={onSwiper}
        autoplay={{
          delay: 5000,
          disableOnInteraction: false,
        }}
        speed={800}
        loop
        onSlideChange={onSlideChange}
      >
        {/* 背景图 */}
        {new Array(5).fill('').map((_, index) => (
          <SwiperSlide key={index}>
            <div
              className={`${styles.characterBg} character${index + 1}`}
            />
          </SwiperSlide>
        ))}
      </Swiper>
      {/* 左侧人物名 */}
      <div className={styles.controller}>
        {new Array(5).fill('').map((_, index) => (
          <div
            className={`${styles.controllerItem} character${index + 1} ${activeIndex === index ? styles.active : ''}`}
            key={index}
            index={index}
            onMouseOver={onCharacterChange}
          >
            <div className={styles.name} />
            <div className={styles.line} />
            <div className={styles.signature} />
          </div>
        ))}
        {/* 倾色浮影标记 */}
        <div className={styles.stamp} />
      </div>
      {/* 下载与适龄提示 */}
      <div className={styles.downloadWrap}>
        <div className={styles.downloadQRCode}>
          <div className={styles.qrcode} style={{ backgroundImage: `url('${data.dCode}')` }} />
        </div>
        <div className={styles.downloadLink}>
          <a
            className={styles.apple}
            href={data.dIos}
            target="_blank"
            rel="noreferrer"
          />
          <a
            className={styles.android}
            href={data.dAndroid}
            target="_blank"
            rel="noreferrer"
          />
        </div>
        <div className={styles.ageTip} onClick={showAgeTip} />
      </div>
      {showScrollTip && <div className={styles.scrollTip} />}
    </div>
  );
};

export default memo(Top);
