import { useRef, useState, useCallback, useEffect } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useScroll } from '@diezhi/yesio';
import InfiniteScroll from 'react-infinite-scroller';

import { videoList } from '@@/service';
import { random } from '@diezhi/momo';
import GalleryItem from '../GalleryItem';
import styles from './style.module.scss';

const VideoGallery = ({
  onClose,
}) => {
  const limit = 12;
  const scrollDivRef = useRef();
  const dataHasRef = useRef(true);
  const { showToTop, scrollToTop } = useScroll(scrollDivRef);
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [randomKey, setRandomKey] = useState('');
  const [data, setData] = useState([]);

  const resetQuery = useCallback(() => {
    setHasMore(true);
    setOffset(0);
    setData([]);
  }, []);

  const loadMore = useCallback(async () => {
    if (dataHasRef.current === false) return;
    try {
      dataHasRef.current = false;
      const res = await videoList({
        key: randomKey,
        offset,
        limit,
      });
      if (res?.length > 0) {
        setOffset((val) => val + limit);
        setData((val) => ([...val, ...res]));
      } else {
        setHasMore(false);
      }
      dataHasRef.current = true;
    } catch (e) {
      dataHasRef.current = true;
    }
  }, [randomKey, limit, offset]);

  const onOrderByTime = () => {
    if (!randomKey) return;
    setRandomKey('');
    resetQuery();
  };

  const onOrderByRandom = () => {
    setRandomKey(random(0, 99999));
    resetQuery();
  };

  return (
    <div className={styles.galleryList}>
      <div onClick={scrollToTop} className={`${styles.toTopBtn} ${showToTop ? styles.show : ''}`} />
      <div className={styles.wrap}>
        <div className={styles.reorderBtn} onClick={onOrderByTime} />
        <div className={styles.random} onClick={onOrderByRandom} />
        <div className={styles.closeBtn} onClick={onClose} />
        <div className={styles.videoSign} />
        <div className={styles.line} />
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={randomKey}
            timeout={200}
            classNames="fade"
          >
            <div className={styles.itemWrap} ref={scrollDivRef}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                useWindow={false}
              >
                {data.map((item) => (
                  <GalleryItem
                    type="video"
                    title={item.title}
                    previewSrc={item.cover}
                    src={item.content}
                    key={item.id}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default VideoGallery;
