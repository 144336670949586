const assets = [];
assets.push([require("./images/img_0.png").default ? require("./images/img_0.png").default: require("./images/img_0.png"), 0]);
assets.push([require("./images/img_1.png").default ? require("./images/img_1.png").default: require("./images/img_1.png"), 1]);
assets.push([require("./images/img_2.png").default ? require("./images/img_2.png").default: require("./images/img_2.png"), 2]);
assets.push([require("./images/img_3.png").default ? require("./images/img_3.png").default: require("./images/img_3.png"), 3]);
assets.push([require("./images/img_4.png").default ? require("./images/img_4.png").default: require("./images/img_4.png"), 4]);
assets.push([require("./images/img_5.png").default ? require("./images/img_5.png").default: require("./images/img_5.png"), 5]);
assets.push([require("./images/img_6.png").default ? require("./images/img_6.png").default: require("./images/img_6.png"), 6]);
assets.push([require("./images/img_7.png").default ? require("./images/img_7.png").default: require("./images/img_7.png"), 7]);
const data = {
  "v": "5.7.14",
  "fr": 30,
  "ip": 0,
  "op": 240,
  "w": 1920,
  "h": 969,
  "nm": "合成 1",
  "ddd": 0,
  "assets": [
    {
      "id": "image_0",
      "w": 913,
      "h": 913,
      "u": "",
      "p": "img_0.png",
      "e": 0
    },
    {
      "id": "image_1",
      "w": 1252,
      "h": 1252,
      "u": "",
      "p": "img_1.png",
      "e": 0
    },
    {
      "id": "image_2",
      "w": 233,
      "h": 233,
      "u": "",
      "p": "img_2.png",
      "e": 0
    },
    {
      "id": "image_3",
      "w": 1226,
      "h": 1226,
      "u": "",
      "p": "img_3.png",
      "e": 0
    },
    {
      "id": "image_4",
      "w": 1021,
      "h": 1021,
      "u": "",
      "p": "img_4.png",
      "e": 0
    },
    {
      "id": "image_5",
      "w": 1053,
      "h": 1053,
      "u": "",
      "p": "img_5.png",
      "e": 0
    },
    {
      "id": "image_6",
      "w": 1277,
      "h": 1277,
      "u": "",
      "p": "img_6.png",
      "e": 0
    },
    {
      "id": "image_7",
      "w": 1578,
      "h": 1578,
      "u": "",
      "p": "img_7.png",
      "e": 0
    }
  ],
  "layers": [
    {
      "ddd": 0,
      "ind": 4,
      "ty": 2,
      "nm": "eff_stroy_world.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 90,
              "s": [
                35
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 150,
              "s": [
                35
              ]
            },
            {
              "t": 180,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            456.5,
            456.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 60,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 180,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 120,
      "op": 180,
      "st": 60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 5,
      "ty": 2,
      "nm": "eff_stroy_world.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 180,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 210,
              "s": [
                35
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 270,
              "s": [
                35
              ]
            },
            {
              "t": 300,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            456.5,
            456.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 180,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 300,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 180,
      "op": 240,
      "st": 180,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 6,
      "ty": 2,
      "nm": "eff_stroy_world.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 120,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 150,
              "s": [
                35
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 210,
              "s": [
                35
              ]
            },
            {
              "t": 240,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            456.5,
            456.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 120,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 240,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 120,
      "op": 240,
      "st": 120,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 7,
      "ty": 2,
      "nm": "eff_stroy_world.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": -60,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": -30,
              "s": [
                35
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 30,
              "s": [
                35
              ]
            },
            {
              "t": 60,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            456.5,
            456.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": -60,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 60,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 60,
      "st": -60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 8,
      "ty": 2,
      "nm": "eff_stroy_world.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 90,
              "s": [
                35
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 150,
              "s": [
                35
              ]
            },
            {
              "t": 180,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            456.5,
            456.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 60,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 180,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 60,
      "op": 120,
      "st": 60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 9,
      "ty": 2,
      "nm": "eff_stroy_world.png",
      "cl": "png",
      "refId": "image_0",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                0
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 30,
              "s": [
                35
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 90,
              "s": [
                35
              ]
            },
            {
              "t": 120,
              "s": [
                0
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            456.5,
            456.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833,
                  0.833,
                  0.833
                ],
                "y": [
                  0.833,
                  0.833,
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167,
                  0.167,
                  0.167
                ],
                "y": [
                  0.167,
                  0.167,
                  0.167
                ]
              },
              "t": 0,
              "s": [
                105,
                105,
                100
              ]
            },
            {
              "t": 120,
              "s": [
                260,
                260,
                100
              ]
            }
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 120,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 10,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 120,
              "s": [
                100
              ]
            },
            {
              "t": 180,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 121,
      "op": 181,
      "st": 60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 11,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 181,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 241,
              "s": [
                100
              ]
            },
            {
              "t": 301,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 181,
      "op": 241,
      "st": 181,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 12,
      "ty": 2,
      "nm": "anim_stroy_world_circle_8.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 121,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 181,
              "s": [
                70
              ]
            },
            {
              "t": 241,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1578.94,
            172.317,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            116.5,
            116.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 121,
      "op": 242,
      "st": 121,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 13,
      "ty": 2,
      "nm": "anim_stroy_world_circle_5.png",
      "cl": "png",
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 121,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 181,
              "s": [
                70
              ]
            },
            {
              "t": 241,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            934,
            973.72,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            613,
            932.72,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 121,
      "op": 242,
      "st": 121,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 14,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": -61,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": -1,
              "s": [
                100
              ]
            },
            {
              "t": 59,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": -1,
      "op": 60,
      "st": -61,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 15,
      "ty": 2,
      "nm": "anim_stroy_word_circle_4.png",
      "cl": "png",
      "refId": "image_1",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 120,
              "s": [
                100
              ]
            },
            {
              "t": 180,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            776.476,
            1010.712,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            626,
            836.342,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 60,
      "op": 121,
      "st": 60,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 16,
      "ty": 2,
      "nm": "anim_stroy_world_circle_8.png",
      "cl": "png",
      "refId": "image_2",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                70
              ]
            },
            {
              "t": 120,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            1578.94,
            172.317,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            116.5,
            116.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 121,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 17,
      "ty": 2,
      "nm": "anim_stroy_world_circle_5.png",
      "cl": "png",
      "refId": "image_3",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                10
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                70
              ]
            },
            {
              "t": 120,
              "s": [
                10
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            934,
            973.72,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            613,
            932.72,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 121,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 19,
      "ty": 2,
      "nm": "anim_stroy_world_circle_4.png",
      "cl": "png",
      "refId": "image_4",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 100,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            510.5,
            510.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 20,
      "ty": 2,
      "nm": "anim_stroy_world_circle_3.png",
      "cl": "png",
      "refId": "image_5",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 25,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 30,
              "s": [
                83
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 48,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 84,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 89,
              "s": [
                83
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 107,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 143,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 148,
              "s": [
                83
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 166,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 205,
              "s": [
                54
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 210,
              "s": [
                83
              ]
            },
            {
              "t": 228,
              "s": [
                54
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            526.5,
            526.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 21,
      "ty": 2,
      "nm": "anim_stroy_world_circle_2.png",
      "cl": "png",
      "refId": "image_6",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 35,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": 0.833,
                "y": 0.833
              },
              "o": {
                "x": 0.167,
                "y": 0.167
              },
              "t": 0,
              "s": [
                866,
                858.5,
                0
              ],
              "to": [
                15.667,
                -62.333,
                0
              ],
              "ti": [
                -15.667,
                62.333,
                0
              ]
            },
            {
              "t": 90,
              "s": [
                960,
                484.5,
                0
              ]
            }
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            638.5,
            638.5,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 22,
      "ty": 2,
      "nm": "bj-1",
      "refId": "image_7",
      "sr": 1,
      "ks": {
        "o": {
          "a": 0,
          "k": 38,
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            576.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            789,
            789,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    },
    {
      "ddd": 0,
      "ind": 23,
      "ty": 2,
      "nm": "bj",
      "refId": "image_7",
      "sr": 1,
      "ks": {
        "o": {
          "a": 1,
          "k": [
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 0,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 60,
              "s": [
                60
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 120,
              "s": [
                100
              ]
            },
            {
              "i": {
                "x": [
                  0.833
                ],
                "y": [
                  0.833
                ]
              },
              "o": {
                "x": [
                  0.167
                ],
                "y": [
                  0.167
                ]
              },
              "t": 180,
              "s": [
                60
              ]
            },
            {
              "t": 240,
              "s": [
                100
              ]
            }
          ],
          "ix": 11
        },
        "r": {
          "a": 0,
          "k": 0,
          "ix": 10
        },
        "p": {
          "a": 0,
          "k": [
            960,
            484.5,
            0
          ],
          "ix": 2,
          "l": 2
        },
        "a": {
          "a": 0,
          "k": [
            789,
            789,
            0
          ],
          "ix": 1,
          "l": 2
        },
        "s": {
          "a": 0,
          "k": [
            100,
            100,
            100
          ],
          "ix": 6,
          "l": 2
        }
      },
      "ao": 0,
      "ip": 0,
      "op": 300,
      "st": 0,
      "bm": 0
    }
  ],
  "markers": []
}

assets.forEach((asset) => {
  const path = typeof asset[0] === 'string' ? asset[0] : asset[0].src;
  const index = asset[1];
  data.assets[index].p = path;
});
module.exports = data;