import { useState, useRef, useCallback, useEffect } from 'react';
import { useScroll } from '@diezhi/yesio';
import InfiniteScroll from 'react-infinite-scroller';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { random } from '@diezhi/momo';

import { pictureList } from '@@/service';
import GalleryItem from '../GalleryItem';
import styles from './style.module.scss';

const CardGallery = ({
  onClose,
}) => {
  const limit = 12;
  const scrollDivRef = useRef();
  const dataHasRef = useRef(true);
  const { showToTop, scrollToTop } = useScroll(scrollDivRef);
  const [wallpaperIndex, setWallpaperIndex] = useState(null);
  const [characterIndex, setCharacterIndex] = useState(() => {
    return new Set();
  });
  const [offset, setOffset] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [randomKey, setRandomKey] = useState('');
  const [data, setData] = useState([]);

  const switchCharacter = (index) => {
    if (characterIndex.has(index)) {
      characterIndex.delete(index);
    } else {
      characterIndex.add(index);
    }
    setCharacterIndex(new Set([...characterIndex]));
  };

  const loadMore = useCallback(async () => {
    if (dataHasRef.current === false) return;
    try {
      const query = {
        key: randomKey,
        offset,
        limit,
      };
      if (wallpaperIndex !== null) {
        Object.assign(query, {
          t1: wallpaperIndex,
        });
      }
      if (characterIndex.size) {
        Object.assign(query, {
          t2: [...characterIndex],
        });
      }
      dataHasRef.current = false;
      const res = await pictureList(query);
      if (res?.length > 0) {
        setOffset((val) => val + limit);
        setData((val) => ([...val, ...res]));
      } else {
        setHasMore(false);
      }
      dataHasRef.current = true;
    } catch (e) {
      dataHasRef.current = true;
    }
  }, [randomKey, limit, offset, wallpaperIndex, characterIndex]);

  const resetQuery = useCallback(() => {
    setHasMore(true);
    setOffset(0);
    setData([]);
  }, []);

  const onOrderByTime = () => {
    if (!randomKey) return;
    setRandomKey('');
    resetQuery();
  };

  const onOrderByRandom = () => {
    setRandomKey(random(0, 99999));
    resetQuery();
  };

  // reset query when type change
  useEffect(() => {
    resetQuery();
  }, [wallpaperIndex, characterIndex, resetQuery]);

  return (
    <div className={styles.galleryList}>
      <div onClick={scrollToTop} className={`${styles.toTopBtn} ${showToTop ? styles.show : ''}`} />
      <div className={styles.wrap}>
        <div className={styles.reorderBtn} onClick={onOrderByTime} />
        <div className={styles.random} onClick={onOrderByRandom} />
        <div className={styles.closeBtn} onClick={onClose} />
        <div className={styles.cardSign} />
        <div className={styles.line} />
        <div className={styles.nav}>
          <div
            className={`${styles.navItemWP} ${wallpaperIndex === null ? styles.active : ''}`}
            onClick={() => { setWallpaperIndex(null); }}
          >
            全部
          </div>
          <div className={styles.separator} />
          <div
            className={`${styles.navItemWP} ${wallpaperIndex === 1 ? styles.active : ''}`}
            onClick={() => { setWallpaperIndex(1); }}
          >
            手机壁纸
          </div>
          <div className={styles.separator} />
          <div
            className={`${styles.navItemWP} ${wallpaperIndex === 2 ? styles.active : ''}`}
            onClick={() => { setWallpaperIndex(2); }}
          >
            电脑壁纸
          </div>
          <div className={styles.separator} />
          <div
            className={`${styles.navItemWP} ${wallpaperIndex === 0 ? styles.active : ''}`}
            onClick={() => { setWallpaperIndex(0); }}
          >
            羁绊展示
          </div>
          <div className={styles.signCharacter} />
          <div
            className={`${styles.navItemCharacter} ${styles.characterAll} ${!characterIndex.size ? styles.active : ''}`}
            onClick={() => { setCharacterIndex(new Set()); }}
          />
          <div
            className={`${styles.navItemCharacter} ${styles.character1} ${characterIndex.has(3) ? styles.active : ''}`}
            onClick={() => { switchCharacter(3); }}
          />
          <div
            className={`${styles.navItemCharacter} ${styles.character2} ${characterIndex.has(1) ? styles.active : ''}`}
            onClick={() => { switchCharacter(1); }}
          />
          <div
            className={`${styles.navItemCharacter} ${styles.character3} ${characterIndex.has(2) ? styles.active : ''}`}
            onClick={() => { switchCharacter(2); }}
          />
          <div
            className={`${styles.navItemCharacter} ${styles.character4} ${characterIndex.has(0) ? styles.active : ''}`}
            onClick={() => { switchCharacter(0); }}
          />
          <div
            className={`${styles.navItemCharacter} ${styles.character5} ${characterIndex.has(4) ? styles.active : ''}`}
            onClick={() => { switchCharacter(4); }}
          />
        </div>
        <SwitchTransition mode="out-in">
          <CSSTransition
            key={`${wallpaperIndex}${[...characterIndex].join(',')}${randomKey}`}
            timeout={200}
            classNames="parallex"
          >
            <div className={styles.itemWrap} ref={scrollDivRef}>
              <InfiniteScroll
                pageStart={0}
                loadMore={loadMore}
                hasMore={hasMore}
                useWindow={false}
              >
                {data.map((item) => (
                  <GalleryItem
                    type="image"
                    title={item.title}
                    previewSrc={item.cover}
                    src={item.content}
                    key={item.id}
                  />
                ))}
              </InfiniteScroll>
            </div>
          </CSSTransition>
        </SwitchTransition>
      </div>
    </div>
  );
};

export default CardGallery;
