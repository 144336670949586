/**
 * 新闻公告-新闻
 */
import { memo, useState, useCallback, useEffect, useRef } from 'react';
import { Autoplay, Pagination } from 'swiper';
import { Swiper, SwiperSlide, useSwiper, useSwiperSlide } from 'swiper/react';
import { useLottie } from '@diezhi/yesio';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import NewsList from '@@/components/NewsList';
import { news } from '@@/service';
import lottieOrbit from '@@/animation/lottieOrbit/data.json';
import MoreNewsList from './components/MoreNewsList';
import { DisplayType, NewsType } from './constants';
import styles from './style.module.scss';

const News = ({
  data: bannerData,
}) => {
  const swiper = useSwiper(); // outer swiper instance
  const swiperSlide = useSwiperSlide();
  const lottieRef = useRef();
  const [data, setData] = useState({
    [NewsType.最新]: [],
    [NewsType.新闻]: [],
    [NewsType.公告]: [],
    [NewsType.活动]: [],
  });
  const [displayType, setDisplayType] = useState(DisplayType.列表);
  const [newsType, setNewsType] = useState(NewsType.最新);

  const { isActive } = swiperSlide;
  const [{ stop, playSegments }] = useLottie(lottieRef, lottieOrbit, { autoplay: false });

  const onCloseMore = useCallback(() => {
    setDisplayType(DisplayType.列表);
    swiper.mousewheel.enable();
  }, [swiper]);

  const onClickMore = () => {
    setDisplayType(DisplayType.更多);
    swiper.mousewheel.disable();
  };

  useEffect(() => {
    if (isActive) {
      playSegments([[1, 45], [46, 450]]);
    } else {
      stop();
    }
  }, [isActive, stop, playSegments]);

  // close modal and enable vertical swiper scroll when nav bar clicked
  useEffect(() => {
    swiper.on('slideChange', onCloseMore);
  }, [swiper, onCloseMore]);

  // fetch news data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await news();
        setData(data);
      } catch (e) {}
    };
    fetchData();
  }, []);
  return (
    <div className={styles.news}>
      <div className={styles.orbit} ref={lottieRef} />
      <div className={`${styles.pageTitle} ${isActive ? styles.active : ''}`} />
      <SwitchTransition mode="out-in">
        <CSSTransition
          key={displayType}
          timeout={200}
          classNames="fade"
        >
          <>
            {displayType === DisplayType.列表 &&
              <div key="list">
                <div className={styles.midWrap} data-swiper-parallax="-50%" data-swiper-parallax-duration="1200">
                  <div className={styles.lycn} />
                  <div className={styles.swiperWrap}>
                    <Swiper
                      className={styles.banner}
                      modules={[Autoplay, Pagination]}
                      autoplay={{
                        delay: 4000,
                        disableOnInteraction: false,
                      }}
                      pagination={{
                        horizontalClass: 'navBar',
                        bulletClass: 'tab',
                        bulletActiveClass: 'cur',
                        clickable: true,
                        renderBullet(_, className) {
                          return (
                            `<span class="${className}"></span>`
                          );
                        },
                      }}
                      loop
                    >
                      {bannerData?.map((item) => (
                        <SwiperSlide key={item.id}>
                          <div
                            style={{ backgroundImage: `url('${item.address}')` }}
                            className={styles.bannerItem}
                            onClick={() => { window.open(`${window.location.origin}/news/${item.info}`); }}
                          />
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </div>

                  <div className={styles.newsListWrap}>
                    <SwitchTransition mode="out-in">
                      <CSSTransition key={newsType} timeout={300} classNames="parallex">
                        <NewsList data={data[newsType].slice(0, 6)} />
                      </CSSTransition>
                    </SwitchTransition>
                  </div>
                </div>
                {/* 查看更多按钮 */}
                <div
                  className={styles.seeMore}
                  onClick={onClickMore}
                />
              </div>}
            {displayType === DisplayType.更多 && <MoreNewsList
              key="more"
              type={newsType}
              onClose={onCloseMore}
            />}
          </>
        </CSSTransition>
      </SwitchTransition>

      {/* 新闻类型导航栏 */}
      {displayType !== DisplayType.详情 &&
        <div className={styles.typeNav}>
          <div
            className={`${styles.navItem} ${styles.navLatest} ${newsType === NewsType.最新 ? 'active' : ''}`}
            onClick={() => { setNewsType(NewsType.最新); }}
          />
          <div className={styles.separator} />
          <div
            className={`${styles.navItem} ${styles.navNews} ${newsType === NewsType.新闻 ? 'active' : ''}`}
            onClick={() => { setNewsType(NewsType.新闻); }}
          />
          <div className={styles.separator} />
          <div
            className={`${styles.navItem} ${styles.navAnnounce} ${newsType === NewsType.公告 ? 'active' : ''}`}
            onClick={() => { setNewsType(NewsType.公告); }}
          />
          <div className={styles.separator} />
          <div
            className={`${styles.navItem} ${styles.navActivity} ${newsType === NewsType.活动 ? 'active' : ''}`}
            onClick={() => { setNewsType(NewsType.活动); }}
          />
        </div>}
    </div>
  );
};

export default memo(News);
