import { useState, useCallback } from 'react';
import styles from './style.module.scss';

const FloatQRCode = ({
  url,
  isSecondary,
}) => {
  const [open, setOpen] = useState(false);

  const onSwitchOpen = useCallback(() => {
    setOpen(!open);
  }, [open]);

  return (
    <div
      className={`
        ${styles.floatQRCode}
        ${open ? styles.active : ''}
        ${isSecondary ? styles.secondary : ''}
      `}
    >
      <div className={styles.qrcode} style={{ backgroundImage: `url('${url}')` }} />
      <div className={styles.handler} onClick={onSwitchOpen}>
        <div className={styles.arrow} />
      </div>
    </div>
  );
};

FloatQRCode.defaultProps = {
  isSecondary: false,
};

export default FloatQRCode;
