/* eslint-disable jsx-quotes */
import usePlayVideo from '@@/hooks/usePlayVideo';
import useDisplayImage from '@@/hooks/useDisplayImage';

import styles from './style.module.scss';

const GalleryItem = ({ type, title, previewSrc, src }) => {
  const playVideo = usePlayVideo();
  const displayImage = useDisplayImage();

  const onClickPlayVideo = (e) => {
    e.stopPropagation();
    playVideo(src);
  };

  const onClickImage = () => {
    if (type !== 'image') return;
    displayImage(src);
  };

  return (
    <div className={styles.item} style={{ backgroundImage: `url('${previewSrc}')` }}>
      <div
        className={`${styles.cover} ${type === 'image' ? styles.pointer : ''} ${type === 'mmd' ? styles.mmd : ''}`}
        onClick={onClickImage}
      >
        {type === 'video' && <div className={styles.playBtn} onClick={onClickPlayVideo} />}
        {type === 'mmd' && (
          <a href={src || '#'}>
            <div className={styles.mmdBtn} />
          </a>
        )}
        <div className={styles.title}>{title}</div>
      </div>
    </div>
  );
};

export default GalleryItem;
