import { useCallback, useState, useEffect, useRef } from 'react';
import { SwitchTransition, CSSTransition } from 'react-transition-group';
import { useSwiper, useSwiperSlide } from 'swiper/react';
import { useImageLoading } from '@diezhi/yesio';
import CharacterDetail from './components/CharacterDetail';
import styles from './style.module.scss';

import c1Img from './components/CharacterDetail/img/character_role_1.png';
import c2Img from './components/CharacterDetail/img/character_role_2.png';
import c3Img from './components/CharacterDetail/img/character_role_3.png';
import c4Img from './components/CharacterDetail/img/character_role_4.png';
import c5Img from './components/CharacterDetail/img/character_role_5.png';
import c1BackImg from './components/CharacterDetail/img/character_role_1_back.png';
import c2BackImg from './components/CharacterDetail/img/character_role_2_back.png';
import c3BackImg from './components/CharacterDetail/img/character_role_3_back.png';
import c4BackImg from './components/CharacterDetail/img/character_role_4_back.png';
import c5BackImg from './components/CharacterDetail/img/character_role_5_back.png';
import bgImg from './img/character_bg.jpg';

const preloadList = [c1Img, c2Img, c3Img, c4Img, c5Img, c1BackImg, c2BackImg, c3BackImg, c4BackImg, c5BackImg, bgImg]
  .map((item) => item.src);

const Character = () => {
  const swiper = useSwiper();
  const [displayType, setDisplayType] = useState('list');
  const [characterIndex, setCharacterIndex] = useState(0);
  const { isActive } = useSwiperSlide();
  const preloadedRef = useRef(false);
  const { run } = useImageLoading(preloadList, { autoLoad: false });

  useEffect(() => {
    if (isActive && !preloadedRef.current) {
      run();
      preloadedRef.current = true;
    }
  }, [isActive]);

  const onClickDetail = (e) => {
    const index = Number(e.currentTarget.dataset.key);
    setCharacterIndex(index || 0);
    setDisplayType('detail');
    swiper.mousewheel.disable();
  };

  const onCloseDetail = useCallback(() => {
    setDisplayType('list');
    swiper.mousewheel.enable();
  }, [swiper]);

  // close modal and enable swiper slide, when nav bar clicked
  useEffect(() => {
    swiper.on('slideChange', onCloseDetail);
  }, [swiper, onCloseDetail]);

  return (
    <SwitchTransition mode="out-in">
      <CSSTransition
        key={displayType}
        timeout={200}
        classNames="fade"
      >
        <>
          {displayType === 'list' &&
            <div className={styles.character}>
              <div className={`${styles.pageTitle} ${isActive ? styles.active : ''}`} />
              <div className={`${styles.title} ${isActive ? styles.active : ''}`} />
              <div className={`${styles.list} ${isActive ? styles.active : ''}`}>
                <div
                  className={`${styles.character1} ${styles.item}`}
                  data-key="0"
                  onClick={onClickDetail}
                >
                  <div className={styles.hoverImg} />
                  <div className={styles.name} />
                </div>
                <div
                  className={`${styles.character2} ${styles.item}`}
                  data-key="1"
                  onClick={onClickDetail}
                >
                  <div className={styles.hoverImg} />
                  <div className={styles.name} />
                </div>
                <div
                  className={`${styles.character3} ${styles.item}`}
                  data-key="2"
                  onClick={onClickDetail}
                >
                  <div className={styles.hoverImg} />
                  <div className={styles.name} />
                </div>
                <div
                  className={`${styles.character4} ${styles.item}`}
                  data-key="3"
                  onClick={onClickDetail}
                >
                  <div className={styles.hoverImg} />
                  <div className={styles.name} />
                </div>
                <div
                  className={`${styles.character5} ${styles.item}`}
                  data-key="4"
                  onClick={onClickDetail}
                >
                  <div className={styles.hoverImg} />
                  <div className={styles.name} />
                </div>
              </div>
            </div>}
          {displayType === 'detail' && (
            <CharacterDetail
              initialIndex={characterIndex}
              onClose={onCloseDetail}
            />
          )}
        </>
      </CSSTransition>
    </SwitchTransition>
  );
};

export default Character;
